import React from 'react'; 
import {createRoot} from 'react-dom/client';
import Login from './Login';


const LoginNode = document.getElementById('loginPanel');
const LoginRoot = createRoot(LoginNode);
LoginRoot.render(<Login/>);





