import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import '../rsna/static/rsna/css/bootstrap.css';


function Login(props) {
    const [csrfToken, setCsrfToken] = useState();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    useEffect(()=>{
        fetch(`/csrf/`,
            {
                method: 'GET',
                credentials: 'include',
            },
        )
        .then((response) => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        })
        .then((data) => {
            setCsrfToken(data['csrfToken']);
        })
        .catch((error) => console.log(error));
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(`/login_request/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
            },
            body: JSON.stringify({ 
                username: name,
                email: email,
             }),
        })
        .then(response => {
            response.json()
        })
        .then(data => {
            window.location.href = "/";
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    return(
        <div className="form-group">
            <form onSubmit={e=>handleSubmit(e)}>
                <label className="col-form-label"><font color="white">Name:</font><font color="red">*</font></label>
                <input type="text" 
                       className="form-control" 
                       placeholder="Enter Your Name"
                       onChange={(e) => setName(e.target.value)}
                    required 
                />

                
                <label className="col-form-label"><font color="white">Email:</font><font color="red">*</font></label>
                <input type="email" 
                       className="form-control" 
                       placeholder="Enter Your Email"
                       onChange={(e) => setEmail(e.target.value)}
                    required></input>

                <div className="modal-footer" style={{border:"None"}}>
                    <button id="login-submit-btn" 
                            type="submit" 
                            className="btn btn-primary"
                            >Submit</button>
                </div>
            </form>
        </div>
    );
}

export default Login;